<template>
  <main>
    <div v-if="!loading && company">
      <Hero :slug="$route.params.slug" :store="company" />
      <div class="bg-white overflow-hidden sm:rounded-lg">
        <div class="px-4 py-5 sm:p-0">
          <table class="table-fixed mx-auto">
            <thead>
              <tr class="text-left">
                <th class="w-1/4 px-4 py-2">
                  Category
                </th>
                <th class="w-3/4 px-4 py-2">
                  Products
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(data, category, index) in products"
                :key="category"
                :class="{ 'bg-gray-100': index % 2 }"
              >
                <td
                  class="border-t px-4 py-2 font-medium text-gray-500 leading-5"
                >
                  {{ category }}
                </td>
                <td class="border-t border-l px-4 py-2 leading-5 text-gray-900">
                  <ul>
                    <li v-for="product in data" :key="product">
                      <router-link :to="$route.params.slug + '/' + product">
                        {{ product }}
                      </router-link>
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <NotFound item="Company" v-if="!loading && !company" />
  </main>
</template>

<script>
import NotFound from "../../components/NotFound.vue";
import Hero from "../../components/Hero.vue";

export default {
  components: {
    NotFound,
    Hero
  },

  data() {
    return {
      loading: true
    };
  },

  computed: {
    company() {
      return this.$store.state.companies[this.$route.params.slug];
    },
    products() {
      if (!this.company) return {};
      const grouped = {};
      Object.keys(this.company.products).forEach(product => {
        const category = this.company.products[product].category;
        if (!grouped[category]) grouped[category] = [];
        grouped[category].push(product);
      });
      return grouped;
    }
  },

  metaInfo() {
    return {
      title: this.company ? this.company.company : "Company not found",
      meta: [
        {
          vmid: "description",
          name: "description",
          content: `Product listings and their certificates of analysis for ${
            this.company ? this.company.company : ""
          } (${this.$route.params.slug})`
        }
      ]
    };
  },

  // Server-side only
  // This will be called by the server renderer automatically
  serverPrefetch() {
    // return the Promise from the action
    // so that the component waits before rendering
    return this.fetchCompany();
  },

  // Client-side only
  mounted() {
    // If we didn't already do it on the server
    // we fetch the item (will first show the loading text)
    if (!this.company) {
      this.fetchCompany();
    } else {
      this.loading = false;
    }
  },

  methods: {
    fetchCompany() {
      // return the Promise from the action
      return this.$store
        .dispatch("fetchCompany", this.$route.params.slug)
        .then(() => (this.loading = false))
        .catch(() => {
          this.loading = false;
        });
    }
  }
};
</script>
